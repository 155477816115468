.profileUserName {
  display: flex;
  color: #fff;
  align-items: center;

  margin-right: 10px;
}
.name #username {
  flex: 1;
  position: fixed;
  color: #184e6d;
}
.logout-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logout-modal .logout {
  margin-right: -20px;
  padding: 4px;
  display: flex;
  width: 20px;
  align-self: center;
  justify-content: center;
  cursor: pointer;
}
.logout_icon {
  width: 25px;
  height: 25px;
}
.logout {
  display: flex;
}
