.create-user {
  /* display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  width: 100%;
  margin-bottom: 10px; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
}
.tab-container {
  /* background-color: blue; */
  z-index: 1;
}
/* .users-pagination {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  align-items: center;
} */
.user-container {
  margin-left: 210px;
  margin-right: 5px;
  background-color: white;
  margin-top: 10px;
  height: 98vh;
  overflow-y: scroll;

  /*  */
}

.user-header {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 68px; */
  /*  */
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-top: 100px;
  width: 100%;
  height: 50px;
  padding-left: 30px;
  padding-right: 50px;
}
.user-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #595959; */
  font-size: 20px;
  font-weight: 600;
}
.create-new-user {
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 15px;
  background: #184e6d;
  border-radius: 4px;
  padding: 4px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 6px;
}
.faplus {
  margin-right: 6px;
}
.users_list {
  margin-top: 120px;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;

  padding: 10px;
  height: 80vh;
}
