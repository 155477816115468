.main-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  /* justify-content: center;
  align-items: center; */
}
.login-img {
  display: flex;
  flex: 1.7;
  /* width: 800px; */
}
.login-image {
  width: 100%;
  /* aspect-ratio: auto 2 / 1; */
  /* aspect-ratio: 16 / 9; */
}
.login-container {
  display: flex;
  height: 100vh;
  flex: 1;
}
.home-layout {
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* width: 110%; */
  justify-content: center;
  align-items: center;
}
.home-layout button {
  border: none;
  padding: 8px;
  width: 80%;
  background-color: #093a58;
  color: white;
}
.home-layout .headings {
  margin-top: 50px;
  font-size: 25px;
  color: #093a58;
  font-weight: 600;
}
.home-layout .signIn {
  border-radius: 4px;
  width: 100%;
  /* box-shadow: 0px 10px 20px rgb(215, 214, 214), 0px -10px 20px rgb(215, 214, 214); */
  border: 1px solid #596376;
  border-bottom: 2px solid #596376;
  font-weight: 600;
  margin-bottom: 50px;
}
