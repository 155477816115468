.sign-out {
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 8px;
  top: 55px;
  right: 15px;
  align-items: center;
  background-color: white;
  cursor: pointer;
  box-shadow: black;
  justify-content: space-around;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 2px 5px 15px 0px;
  border-top: 1px solid #b4b6b8;
  border-left: 1px solid #b4b6b8;
}
.sign-out #caretdown {
  position: absolute;
  top: -21px;
  right: 4px;
  color: white;
}
.sign-out h8 {
  color: #2a2b2c;
  margin-left: 5px;
  font-size: 13px;
}
.logout-pop-up-container{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}
.logout-pop-up {
  position: relative;
  width: 500px;
  height: 200px;
}
.confirmation-button{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: absolute;
  width: 500px;
  bottom: 10px;
}
