* {
  font-family: Lato;
}
.candidates-details {
  display: flex;
  background-color: white;
  margin-top: 10px;
  margin-left: 210px;
  margin-right: 5px;
  flex-direction: column;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-top: 3px solid #0ab5a9;
  border-radius: 8px;
  height: 98vh;
}
.candidates-details-inProgress {
  display: flex;
  background-color: white;
  margin-top: 10px;
  margin-left: 210px;
  margin-right: 5px;
  flex-direction: column;
  height: 100%;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  /* border-top: 3px solid #0ab5a9; */
  border-radius: 8px;
  height: 100vh;
}
.addAndDownload-btn {
  display: flex;
  align-items: center;
  gap: 15px;
}
.select-btn {
  background-color: #093a58;
  color: #fff;
  height: 40px;
  width: 150px;
  text-align: center;
  padding-top: 6px;
  border-radius: 5px;
  cursor: pointer;
}
.addSingleCandidate {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #595959; */
  background-color: #093a58;
  color: #fff;
  height: 40px;
  width: 160px;
  padding-top: 6px;
  /* text-align: center;
  padding-top: 6px;
  cursor: pointer;
  z-index: 2; */
}
/* btn-container {
  border: 1px solid #4f4f4f;
  background-color: #e1e2e2;
} */
.addCandidateContainer {
  position: absolute;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  padding-top: 1px;
}
.addBulkCandidate {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #093a58;
  color: #fff;
  height: 40px;
  width: 160px;
  padding-bottom: 6px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  /* text-align: center;
  padding-top: 6px;
  cursor: pointer;
  margin-top: 40px;
  z-index: 2;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; */
}
.download-btn {
  background-color: #093a58;
  color: #fff;
  height: 40px;
  width: 150px;
  text-align: center;
  padding-top: 6px;
  border-radius: 5px;
  cursor: pointer;
}
.upload-download {
  margin-top: 60px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  margin-left: 25px;
  margin-bottom: 10px;
  padding-right: 10px;
}
.upload-download-Progress {
  margin-top: 20px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  margin-left: 25px;
  margin-bottom: 10px;
  padding-right: 10px;
}
.in_progress_tabbar {
  margin-top: 5px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  margin-left: 25px;
  margin-bottom: 10px;
  padding-right: 10px;
}
.upload-button {
  display: flex;
  color: white;

  align-items: center;
  border-radius: 4px;

  margin-left: 2px;
  color: white;
  font-size: 16px;
  background-color: rgb(24, 78, 109);
  border-radius: 4px;
  padding: 4px 20px;
}
.download-button {
  display: flex;
  color: white;

  align-items: center;
  border-radius: 4px;
  margin-right: 20px;
  margin-left: 2px;
  color: white;
  font-size: 16px;
  background-color: rgb(24, 78, 109);
  border-radius: 4px;
  padding: 4px 20px;
}

.search-filter {
  display: flex;
  height: 100px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #f8f8f8;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  border-radius: 4px;
}
.candidate_list {
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  /* max-height: 24rem; */
  padding: 10px;
  height: 80vh;

  overflow-y: scroll;
}
.send_page {
  display: flex;
  justify-content: center;
  /* margin-top: 15px; */
}
.mail-button button {
  border: none;
  background-color: #093a58;
  color: white;
  height: 40px;
  width: 150px;
  color: white;
  display: flex;
  align-items: center;
  /* align-self: center; */
  justify-content: space-around;
  cursor: pointer;
  font-size: 15px;
  /* background-color: rgb(24, 78, 109); */
  border-radius: 4px;
  /* padding: 4px 20px; */
}
.mailDropDown {
  position: absolute;
  text-align: center;
  background-color: #f8f8f8;
  margin-top: 1px;
  width: 150px;
  /* top: 0px; */
  z-index: 3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #e1e2e2;
}
.mailOptions {
  /* color: #fff; */
}

.filter-icon {
  display: flex;
  align-items: center;
  background-color: #e4e5e5;
  border-radius: 4px;
  border: 1px solid #4f4f4f;
  padding-left: 6px;
  padding-right: 6px;
}
.candidates-pagination {
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;
  align-self: center;
  cursor: pointer;
}
.candidate_header {
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #595959; */
  font-size: 20px;
  font-weight: 600;
}
.in_progress_tab {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-filter .searchbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-left: 10px;
}
.search-filter .perpagesize {
  display: flex;
  flex: 2;
  align-self: center;
}
.filter-icon-filter {
  display: flex;
  cursor: pointer;
  margin-right: 4px;
}
.search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.Drop-btn {
  display: flex;
  width: 80%;
  border: 1px solid;
  /* color: #CCC; */
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 15px;
}
.SelectOption {
  position: absolute;
  flex-direction: column;
  margin-top: 25px;
  background-color: #f8f8f8;
  margin-left: -10px;
  border: 1px solid rgb(237, 237, 240);
  background-color: #f8f8f8;
  z-index: 1;
  width: 120px;
  padding-top: 10px;
}
.search-label {
  margin-right: 10px;
}
.score-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}
.DropDown-btn {
  display: flex;
  width: 120px;
  cursor: pointer;
  border: 1px solid #e1e2e2;
  color: #545555;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 15px;
  background-color: #fff;
}
.input-div {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  /* border: 1px solid #e1e2e2; */
  background: white;
  align-items: center;
  border-radius: 4px;
}
.input-div input {
  border: 1px solid #e1e2e2;
}
.loader {
  position: absolute;
  left: 50%;
  top: 51%;
  right: 50%;
  bottom: 50%;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #093a58;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*  */
