.candidateDetailsContainer {
  margin-top: 10px;
  margin-left: 210px;
  margin-right: 8px;

  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-top: 3px solid #0ab5a9;
  border-radius: 8px;
  height: 98vh;
  background-color: #fff;
  /* display: flex; */
}
/* .profile_topbar {
  margin-left: 210px;
} */
.editProfile {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 5%;
  display: flex;
  justify-content: space-between;
}
.candidateNameProfile {
  font-size: 20px;
  font-weight: 600;
  /* position: absolute; */
  /* top: 10%; */
  /* margin-left: 18px; */
}
.editbtn {
  /* background-color: aquamarine; */
  height: 40px;
  width: 150px;
  text-align: center;
  padding-top: 5px;
  margin-right: 35px;
  background-color: #093a58;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
}
.Resume-viewer-container {
  text-decoration: none;
  text-align: center;
  align-self: center;
}
.Resume-viewer {
  padding-top: 6px;
  /* margin-top: 10px; */
  height: 40px;
  width: 120px;

  color: #fff;
  background-color: #093a58;
  text-align: center;
  border-radius: 6px;
  align-self: center;
}
.profile-wrapper {
  width: 80%;
  display: flex;
  /* background-color: gray; */
  justify-content: center;

  align-items: center;

  height: 100px;
  flex-direction: row;
  margin-left: 10%;
}

.candidateInfoTable {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.table1 {
  /* flex: 1; */
  /* background-color: red; */
  width: 50%;
  height: 50px;
}
.table2 {
  /* flex: 1; */
  width: 50%;
  height: 50px;
  justify-content: flex-end;
}
.lable {
  font-size: 16px;
}
.lableValue {
  font-size: 20px;
}
.candidateDetails {
  /* background-color: #0AB5A9; */
  display: flex;
  /* flex: 1; */
  /* width: 80%; */
  /* justify-content: space-around; */
}
.candidate-name {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.candidate-email {
  display: flex;
  flex: 1;
  flex-direction: column;
}
