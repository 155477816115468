.layoutContainer {
  display: flex;
  margin-left: 210px;
  margin-right: 5px;
  background-color: white;
  border-top: 3px solid #0ab5a9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  height: 98vh;
  margin-top: 10px;
}
.cardContainer {
  margin-top: 15px;
  display: flex;
  gap: 40px;
  margin-left: 20px;
}
.unotherized_user {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
/* Fallback for Safari, Chrome, and Opera */
input[type="file"]::-webkit-file-upload-button {
  background-color: #093a58;
  color: #fff;
  border: none;
  border-radius: 4px;
  height: 38px;
  width: 140px;
}
/* Fallback for Legacy Edge and IE */
input[type="file"]::-ms-browse {
  background-color: #093a58;
  color: #fff;
  border: none;
  border-radius: 4px;
  height: 40px;
}
/* Standard */
input[type="file"]::file-selector-button {
  background-color: #093a58;
  color: #fff;
  border: none;
  border-radius: 4px;
  height: 40px;
  /* display: none; */
}
