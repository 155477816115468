.newcandidateform {
  display: flex;
  flex-direction: column;
  width: 80%;
  /* gap: 20px; */
  /* overflow-y: scroll; */
}
.candidatename {
  display: flex;
  flex: 1;
}
.firstname {
  flex: 1;
}
.lastname {
  flex: 1;
}
.forn-input {
  width: 80%;
  height: 45px;
  border-radius: 15px;
  /* border-color: blue; */
  border: 1px solid;
  /* color: #CCC; */
  padding-left: 10px;
  border: 1px solid #e1e2e2;
}
.resume input {
  width: 40%;
  border: 1px solid #e1e2e2;
  height: 45px;
  padding-top: 1.5px;
  border-radius: 6px 15px 15px 6px;
}

.inputvalue {
  background-color: blue;
}
.contactInfo {
  display: flex;
  flex-direction: row;
}
.boxContainer {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px 0px 10px 0px;
}

.dropDownAndDateInputBox {
  flex: 1;
  position: relative;
}
.SelectOptions {
  cursor: pointer;
  position: absolute;
  background-color: #f8f8f8;
  color: black;
  z-index: 1;
  width: 80%;
  padding-top: 10px;
}

.PostiondropDown {
  position: relative;
  flex: 1;
}
.dateInput {
  display: flex;
  width: 80%;
  height: 40px;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #e1e2e2;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 15px;
}
.departmentDropDown-btn {
  display: flex;
  width: 80%;
  height: 40px;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #e1e2e2;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 15px;
}
.UploadCV {
  display: flex;
  flex-direction: row;
}
.disabledCV {
  display: none;
}
.resume {
  width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-start;
  justify-content: center; */
}
.Notice_period {
  flex: 1;
}
.dropdownOption {
  padding-left: 10px;
}
.filledRequiredField {
  display: none;
}
.unfilledRequiredField {
  color: red;
  font-size: 12px;
}
.successpopView {
  display: flex;
  position: absolute;
  left: calc(50%-210px);
  right: calc(50%-210px);
  top: 100%;
  z-index: 2;
  bottom: 50%;
}
