.candidateForm {
  display: flex;
  background-color: white;
  margin-top: 10px;
  margin-left: 210px;
  margin-right: 5px;
  flex-direction: column;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-top: 3px solid #0ab5a9;
  border-radius: 8px;
  height: 98vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.candidateForm::-webkit-scrollbar {
  display: none;
}

.action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  padding-right: 100px;
  width: 85%;
  position: absolute;
  z-index: 1;
  height: 80px;
  align-items: center;
}

.saveandcancle {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.cancel {
  background-color: #093a58;
  color: #fff;
  height: 40px;
  width: 150px;
  text-align: center;
  padding-top: 6px;
  border-radius: 5px;
  cursor: pointer;
}

.save {
  background-color: #093a58;
  color: #fff;
  height: 40px;
  width: 150px;
  text-align: center;
  padding-top: 6px;
  border-radius: 5px;
  cursor: pointer;
}

.Inputfield {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 20px;
}
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding-left: 100px;
  font-weight: 600;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
