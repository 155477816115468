.new_user {
  margin-top: 10px;
  margin-left: 210px;
  margin-right: 5px;
  background-color: white;
  border-top: 3px solid #0ab5a9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  height: 98vh;
}

.user_profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-top: 10px;
}
.profile-heading {
  font-weight: 600;
  margin-left: 40px;
  font-size: 20px;
}
.save-btn {
  margin-right: 40px;
}
.user_form {
  display: flex;

  background-color: white;
  padding-left: 40px;
  padding-top: 50px;

  height: 50%;
}
.Candidate_form {
  width: 50%;
}
.label-box {
  margin-bottom: 15px;
}

.save-user button {
  margin-left: 15px;
  border: none;
  background-color: #184e6d;
  color: white;
  font-size: large;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 40px;
  width: 25%;
}

.label-input-section span {
  color: red;
}
.label-input-section input:focus {
  outline: none;
  border: 1px solid rgb(237, 237, 240);
}
.dropdown .dropdown-content {
  position: absolute;
  top: 102%;
  width: 58%;
  background: white;
  box-shadow: 3px 3px 10px 6px rgb(0 0 0 / 6%);
}
.dropdown-select-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(237, 237, 240);
  border-radius: 5px;
  height: 30px;
  padding: 4px;
  padding-left: 12px;
  padding-right: 10px;
}

.dropdown-select {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.dropdown-select-content {
  width: 100%;
  border: 1px solid srgb(237, 237, 240);
  padding-left: 12px;
  cursor: pointer;
}
.label-input-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.label-input-section input {
  color: rgb(237, 237, 240);
  height: 30px;
  border-radius: 5px;
  width: 100%;
  color: rgb(73, 72, 72);
  border: 1px solid rgb(237, 237, 240);
  margin-top: 4px;
}
.active-dropdown {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-top: 3.5rem;
  border: 1px solid rgb(237, 237, 240);
  padding-left: 8px;
}
.error {
  color: red;
}
