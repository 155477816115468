.sidebar {
  width: 200px;
  position: fixed;
  top: 0px;
  height: 100vh;
  background: #093a58;
  z-index: 3;
  color: white;
  left: 0;
  transition: 0.4s ease-in;
}

.panorbit-logo {
  width: 180px;
}

.logo {
  margin-top: 30px;
  padding-left: 10px;
  cursor: pointer;
}
.sidebar-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 40px;
  align-items: center;
  padding-left: 10px;
}
.sidebarText {
  font-size: 16px;
  padding-left: 10px;
  cursor: pointer;
}
.sidebarchildText {
  font-size: 16px;
  padding-left: 30px;
  cursor: pointer;
}
.accountName {
  position: absolute;
  width: 100%;
  display: flex;
  bottom: 20px;
  /* left: 50%; */

  /* background-color: black; */
  align-items: center;
  justify-content: center;
  /* justify-content: space-around; */
  /* left: 20px;
  bottom: 50px; */
}
