.sticky-nav {
  position: fixed;
  width: 100%;
  height: 42px;
  top: 0;
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 1px 1px silver;
}
.welcome {
  margin-right: 200px;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-left: 210px;
  color: #184e6d;
  font-size: 14px;
  font-weight: 600;
}
.home-heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-left: 90px;
  color: #184e6d;
  font-size: 14px;
  font-weight: 600;
}
.name-logout {
  display: flex;
  flex-direction: column;
}
.welcome-div {
  /* margin-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; */
}
 .left-icon{
  cursor: pointer;
  padding: 4px;
}