.fileuploading {
  /* display: flex;
  margin-left: 210px;
  background-color: white;
  margin-top: 10px;
  margin-right: 5px;
  height: 98vh;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px; */
  display: flex;
  background-color: white;
  margin-top: 10px;
  margin-left: 210px;
  margin-right: 5px;
  flex-direction: column;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-top: 3px solid #0ab5a9;
  border-radius: 8px;
  height: 98vh;
}
.filelabel {
  margin-left: 76px;
  margin-top: 80px;
  margin-bottom: 10px;
  color: rgb(77, 75, 75);
}
.input_file {
  display: flex;
  width: 100%;
  /* background-color: aqua; */
  flex-direction: column;
}
.upload-file {
  color: rgb(77, 75, 75);
  /* font-size: small; */
  display: flex;
  margin-left: 76px;
  flex-direction: column;
  width: 45%;
}
.upload-file form input {
  /* padding: 6px; */
  border-radius: 4px;
  width: 100%;
  border: 1px solid rgb(182, 179, 179);
}
.submit_btn {
  border: none;
  margin-top: 80px;
  height: 35px;
  width: 500px;
  color: white;
  background-color: #093a58;
  border-radius: 4px;
  /* padding-left: 200px;
  padding-right: 200px;
  padding-top: 3px;
  padding-bottom: 3px;
   */
  align-self: center;
}
.bulk-heading {
  font-weight: 600;
  margin-left: 40px;
  font-size: 20px;
}
.sample-btn {
  margin-right: 40px;
}
.bulkCandidate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  /* background-color: aqua; */
  /* margin-left: 40px; */
  /* margin-right: 80px; */
  margin-top: 20px;
}
